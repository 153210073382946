@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Red+Rose:wght@300..700&display=swap");

.ff_redrose {
  font-family: "Red Rose", serif;
}

.ff_heebo {
  font-family: "Heebo", sans-serif;
}

* {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  box-sizing: border-box;
  text-decoration: none !important;
}

:root {
  /*  ------------------------------- font size ------------------------------- */
  --fs-xs: 7px;
  --fs-sm: 12px;
  --fs-md: 15px;
  --fs-lg: 16px;
  --fs-xl: 17px;
  --fs-xxl: 18px;
  --fs-3xl: 22px;
  --fs-4xl: 24px;
  --fs-5xl: 26px !important;
  --fs-6xl: 27px;
  --fs-7xl: 29px;
  --fs-8xl: 37px;
  --fs-9xl: 40px;
  --fs-10xl: 43px;
  --fs-11xl: 52px;
  --fs-12xl: 140px;
  /* --------------------------------- Color --------------------------------- */
  --text-darkgray: #333333;
  --text-darkgreen: #007f3e;
  --text-blue: #443184;
  --text-yellow: #fbc52e;
  --text-pink: #af008c;
  --text-green: #008373;
  --text-lightwhite: #f7f5ff;
  --text-lightblack: #191919;
  /* ---------------------------------bg color--------------------------------- */
  --bg-darkblue: #44318d;
  --bg-lightyellow: #fdfae9;
  --bg-lightgreen: #e9fdf4;
  --bg-lightblue: #ede9fd;
  --bg-lightpink: #fde9f9;
  --bg-yellow: #f4b621;
  --bg-lightyellow2: #ffef9c;
}

.fs_7 {
  font-size: var(--fs-xs);
}

.fs_12 {
  font-size: var(--fs-sm);
}

.fs_14 {
  font-size: 14px !important;
}

.fs_15 {
  font-size: var(--fs-md);
}

.fs_16 {
  font-size: var(--fs-lg);
}

.fs_17 {
  font-size: var(--fs-xl);
}

.fs_20 {
  font-size: 20px;
}

.fs_18 {
  font-size: var(--fs-xxl);
}

.fs_22 {
  font-size: var(--fs-3xl);
}

.fs_24 {
  font-size: var(--fs-4xl);
}

.fs_26 {
  font-size: var(--fs-5xl);
}

.fs_27 {
  font-size: var(--fs-6xl);
}

.fs_29 {
  font-size: var(--fs-7xl);
}

.fs_37 {
  font-size: var(--fs-8xl);
}

.fs_40 {
  font-size: var(--fs-9xl);
}

.fs_43 {
  font-size: var(--fs-10xl);
}

.fs_52 {
  font-size: var(--fs-11xl);
}

.fs_140 {
  font-size: var(--fs-12xl);
}

.color_darkgray {
  color: var(--text-darkgray) !important;
}

.color_darkgreen {
  color: var(--text-darkgreen) !important;
}

.color_blue {
  color: var(--text-blue);
}

.color_lightwhite {
  color: var(--text-lightwhite);
}

.color_lightblack {
  color: var(--text-lightblack);
}

.color_pink {
  color: var(--text-pink);
}

.color_green {
  color: var(--text-green);
}

.color_yellow {
  color: var(--text-yellow);
}

/* bg */

.bg_darkblue {
  background-color: var(--bg-darkblue) !important;
}

.bg_lightblue {
  background-color: var(--bg-lightblue);
}

.bg_lightyellow {
  background-color: var(--bg-lightyellow);
}

.bg_lightyellow2 {
  background-color: var(--bg-lightyellow2);
}

.bg_lightgreen {
  background-color: var(--bg-lightgreen);
}

.bg_lightpink {
  background-color: var(--bg-lightpink);
}

.bg_yellow {
  background-color: var(--bg-yellow);
}

/* ---------------------------------Custom Classes Start--------------------------------- */

/* -----------------Navbar----------------- */

.custom_zindex {
  z-index: 1234567;
}

.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  width: 35px;
}

.burger div {
  width: 35px;
  height: 1px;
  background-color: #fff;
  border: 2px solid #fff;
  transition: 0.3s;
  margin-top: 5px;
  margin-bottom: 5px;
}

.burger.active div:nth-child(1) {
  transform: rotate(-42deg) translate(-6px, 6px);
}

.burger.active div:nth-child(2) {
  opacity: 0;
}

.burger.active div:nth-child(3) {
  transform: rotate(45deg) translate(-6px, -6px);
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #443184;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  transition: all 400ms ease-in-out;
}

.login_btn {
  border: 1px solid #ffffff;
  padding: 7px 40px;
  background-color: transparent;
  border-radius: 3px;
}

.Free_btn {
  padding: 8px 40px;
  background: linear-gradient(90deg, #fbc52e 0%, #f6960c 100%);
  border-radius: 3px;
}

.after_line::after {
  content: "";
  width: 0;
  border: 1px solid #f4b621;
  background-color: #f4b621;
  position: absolute;
  bottom: -5px;
  display: block;
  transition: all 400ms ease-in-out;
  opacity: 0;
}

.position-relative:hover::after {
  width: 100% !important;
  opacity: 1;
}

.cross_icon {
  top: 20px;
  right: 20px;
}

/* --------------------------Hero Start-------------------------- */

.Hero_bg {
  background: linear-gradient(
    180deg,
    #e4defe 0%,
    #ece8fe 0.01%,
    #ffffff 91.37%
  );
}

.hero_cart {
  background-color: white;
  box-shadow: 0px 4px 56.29999923706055px 0px #00000024;
  border-radius: 8px;
  border-bottom: 2px solid #f6960c;
}

.custom_hero_image_class {
  transform: translateY(-80px);
}

.custom_margin {
  margin-top: -70px;
}

.custom_hero_icon1 {
  bottom: -35px;
  right: -35px;
}

.bg_darkyellow {
  background-color: #f4b621;
}

.bg_darkblue {
  background-color: #44318d;
}

.custom_slider_image {
  max-width: 340px;
}

.slick-dots {
  bottom: -42px !important;
}

.coding .slick-dots {
  bottom: -10px !important;
}

.centers_dotes .slick-dots {
  bottom: 0px !important;
}

.centers_dotes .slick-dots li button:before {
  font-size: 11px !important;
  color: #443184 !important;
}

.slick-dots li button:before {
  font-size: 11px !important;
  color: #f6960c !important;
}

.centers_dotes .slick-slider .slick-track,
.slick-slider .slick-list {
  padding-bottom: 20px !important;
}

.custom_rounded {
  border-radius: 60px 5px 5px 5px;
}

.custom_bottom_rounded {
  border-radius: 5px 5px 60px 5px !important;
}

.custom_bottom_space {
  bottom: -200px;
  opacity: 0px;
}

.custom_skill_icon {
  right: 150px;
  top: -80px;
}

.custom_hero_position_icon {
  top: 70px;
  right: 100px;
}

.custom_hero_position_icon1 {
  top: 20px;
  right: -50px;
}

.custom_end_space {
  right: 80px;
}

.features_box:hover {
  background-color: #44318d;
}

.features_box:hover p {
  color: white !important;
}

.features_box p {
  transition: all 300ms ease-in-out;
}

.features_box:hover h5 {
  color: white !important;
}

.features_box h5 {
  transition: all 300ms ease-in-out;
}

.features_box {
  background-color: #fffced;
  transition: all 300ms ease-in-out;
}

.features_box1 {
  background-color: #fffced;
  transition: all 300ms ease-in-out;
}

.pagenation_number {
  border: 1px solid #ccc;
  padding: 7px 13px;
}

.custom_col_witdh {
  width: 12.5% !important;
}

.job_section_custom_top_space7 {
  margin-top: 43px !important;
  transform: translateX(7px);
}

.job_section_custom_top_space6 {
  margin-top: 83px !important;
  transform: translateX(14px);
}

.job_section_custom_top_space5 {
  margin-top: 123px !important;
  transform: translateX(21px);
}

.job_section_custom_top_space4 {
  margin-top: 162px !important;
  transform: translateX(27px);
}

.job_section_custom_top_space3 {
  margin-top: 200px !important;
  transform: translateX(34px);
}

.job_section_custom_top_space2 {
  margin-top: 238px !important;
  transform: translateX(40px);
}

.job_section_custom_top_space1 {
  margin-top: 274px !important;
  transform: translateX(47px);
}

.custom_position_image {
  top: -50px;
}

.job_section .row > * {
  padding: 0px !important;
}

/* ----------------------curriculum---------------------- */

.curriculum_bg {
  background: #f4b62138;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-button:focus {
  box-shadow: 4px 10px 20px transparent !important;
}

.accordion-body {
  padding: 0px !important;
}

.custom_faq .accordion-button:not(.collapsed) {
  background-color: #fffcf4 !important;
}

.custom_faq .accordion-item {
  background-color: #fffcf4;
}

.custom_faq .accordion-button {
  background-color: #fffcf4 !important;
  border-radius: 0 !important;
}

.custom_faq {
  max-width: 774px !important;
  margin: 0 auto;
}

.custom_list_style_type {
  list-style-type: disc !important;
}

.custom_border {
  border: 1px solid #ffffff33 !important;
}

.curriculum_box_shadow {
  box-shadow: 0px 32px 51.20000076293945px 0px #44318d4a;
}

.selected-course {
  padding: 16px 13px;
  background: linear-gradient(90deg, #fbc52e 0%, #f6960c 100%);
  border-radius: 3px;
  cursor: pointer;
  margin-top: -4px;
}

.cursor_pointer {
  cursor: pointer;
}

.opacity {
  /* opacity: 0px !important; */
  z-index: -1 !important;
}

.custom_width {
  width: 180px;
}

/* ----------------------------career---------------------------- */

.career_background {
  background: linear-gradient(94.97deg, #ece7ff 7.94%, #f4b621 89.24%);
}

.career_box {
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.career_cart {
  box-shadow: 0px 18.354366302490234px 23.77327537536621px 0px #0000001a;
}

.clip_path {
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
}

.custombule_career_offer {
  right: -50px;
  top: -15px;
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
}

.custompink_career_offer {
  right: -50px;
  top: 60px;
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
}

.customyellow_career_offer {
  right: -50px;
  top: 135px;
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
}

.customgreen_career_offer {
  right: -50px;
  top: 210px;
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
}

.yellow_bottom_border {
  border-bottom: 7px solid #fbc52e;
}

.scholarship_box {
  background-color: #dad9ff;
  border-radius: 10px 50px 10px 50px;
}

.scholarshipyellow_box {
  background-color: #fff4d9;
  border-radius: 10px 50px 10px 50px;
}

.custom_scholarship_icon {
  top: -45px;
  background-color: #ffe6aa !important;
  padding: 17px 10px;
  border-radius: 100px;
}

.custom_scholarshipyellow_icon {
  top: -45px;
  background-color: #44318d !important;
  padding: 17px 10px;
  border-radius: 100px;
}

.View_btn {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #dad9ff 100%);
  border-right: 3.41px solid #44318d !important;
  border-radius: 7px;
}

.custom_input {
  border: 2px solid #f4b621;
}

input {
  outline: none;
  border: none;
}

/* -------------------2 Page start------------------- */

.custom_top_space {
  top: 20px !important;
}

.details_box {
  border-radius: 5px 80px 5px 5px;
  border-left: 12px solid #f4b621;
}

.language_box {
  background-color: #513ca1;
}

.Trial_border {
  border: 1px solid #f4b621;
  border-radius: 10px !important;
}

.BuyNow_btn {
  background: linear-gradient(256.11deg, #fac12b 19.95%, #f6990e 59.83%);
}

.coures_btn {
  background-color: #fff6cb;
}

.courses .accordion-button {
  background-color: #fffced !important;
}

.accordion-item {
  border: 0 solid transparent !important;
  border-bottom: 1px solid #ffee99 !important;
}

.courses .accordion-item {
  background-color: #fffced !important;
  border-top: 0px solid transparent #ffee99;
  padding-top: 0px !important;
}

.bg_lightgray {
  background-color: #f3f3f3;
}

.bg_red {
  background-color: #d80000;
}

.course_title {
  line-height: 35.4px;
}

.gothrow_price {
  text-decoration: line-through !important;
}

.centers_card {
  background-color: #f4f1ff;
  border-radius: 8px;
  border-bottom: 3px solid #44318d;
}

.mt-134 {
  margin-top: 134px;
}

.centerSiderItem {
  background-color: #f4f1ff;
  min-height: 293px;
}

.centercompanylogoplacement {
  bottom: 15px;
  right: 15px;
}

.centerImage {
  border-radius: 19px;
}

.centerImageShandow {
  box-shadow: 0 19px 30.3px #44318d5e;
}

.image_image {
  position: absolute;
  left: -40px;
  top: 17px;
}

.OurCentres {
  border: 2px solid;
  box-shadow: 0px 41px 81px 0px #0000001a;
  border-color: #e9e8e4;
  background-color: #f8f6ff;
}

.custom-border {
  border-right: 2px dotted #44318d;
}

.custom_list_style_type_number {
  list-style-type: number;
}

.slider_box {
  background-color: #fff8d8;
  border: 0.82px solid #ffffff;
}

.couser_header_border {
  border-bottom: 1px solid #ececec2b;
}

.border-right-dashed {
  border-right: 5px dashed var(--bg-yellow);
}

.liststyle {
  list-style-type: disc !important;
}

.heigltbox {
  background-color: #fffced;
}

.courseFagbg > .accordion-button {
  background: #f4f1ff !important;
}

.comparecontainer {
  background-color: #fffced !important;
}

table > :not(caption) > * > * {
  background-color: #fffced !important;
}

.table_bg_yellow {
  background-color: #f4b621 !important;
}

.borderTopleft {
  border-top-left-radius: 6px;
}

.borderTopRight {
  border-top-right-radius: 6px;
}

.border-right {
  border-right: 1px solid white;
}

.border-left {
  border-left: 1px solid white;
}

.border-right-yellow {
  border-right: 1px solid var(--bg-yellow);
}

.border-left-yellow {
  border-left: 1px solid var(--bg-yellow);
}

.border-bottom-yellow {
  border-bottom: 1px solid var(--bg-yellow);
}

.borderbottomright {
  border-bottom-right-radius: 6px;
}

.borderbottomleft {
  border-bottom-left-radius: 6px;
}

.z_index {
  z-index: 1234 !important;
}

.job_bg {
  background-color: #fefbf4;
}

.custom_bottom_padding_space {
  padding-bottom: 200px !important;
}

.custom_top_space {
  margin-top: -230px !important;
}

.custom_y_space {
  padding: 190px 0 !important;
}

.box {
  box-shadow: 0px 23px 81.19999694824219px 0px #00000040;
  background-color: #faf8ff;
  margin-top: -570px;
}

.form_position_img {
  width: 130px !important;
  top: 0;
  left: 0;
}

.z_index1 {
  z-index: 123;
}

.counselling .accordion-item {
  background-color: transparent !important;
}

.counselling .accordion-button {
  background-color: transparent !important;
}

.border_blue {
  border-bottom: 1px solid #443184 !important;
}

.custom_bg {
  background-color: #f7f5ff72;
}

.color_red {
  color: #d40000;
}

.nav-item:hover {
  background-color: #9a96a748;
  padding: 4px 16px;
  border-radius: 4px;
}

.custom_bg {
  background-color: #9a96a748;
}

.max_w {
  max-width: 800px !important;
}

.custom_admin_input {
  border: 1px solid #837c7c80;
  border-radius: 0px;
}

.card {
  box-shadow: 4px 3px 19px #3333337f;
  border-radius: 20px !important;
}

.modal-dialog {
  max-width: 700px !important;
}

.short-description-wrapper {
  overflow: hidden;
}

.long-description {
  display: none;
}

.hero_cart:hover .long-description {
  display: block;
}

/* 
.d_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid1 {
  grid-row: 1/3;
  grid-column: 2/3;
}
.grid2 {
  grid-row: 3/4;
  grid-column: 1/3;
}
.grid3 {
  grid-row: 2/5;
  grid-column: 3/4;
}
.grid4 {
  grid-row: 4/5;
  grid-column: 3/5;
} */

.timeline_box1 {
  top: 60px !important;
}

/* 
.timeline_box2 {
  border: 4px solid transparent; 
  border-image-source: linear-gradient(
    264.4deg,
    #f4b621 11.83%,
    rgba(244, 182, 33, 0) 44.29%
  );
  border-image-slice: 1;
} */
.timeline_box2 {
  border: 3.73px solid transparent;
  border-radius: 0 24px 0 0;
  background: linear-gradient(
        264.4deg,
        #f4b621 11.83%,
        rgba(244, 182, 33, 0) 44.29%
      )
      padding-box,
    linear-gradient(264.4deg, #f4b621 11.83%, rgba(244, 182, 33, 0) 44.29%)
      border-box;
  height: 100%;
  transition: 0.4s ease-in-out;
}

.timeline_box3 {
  border: 3.73px solid transparent;
  border-radius: 0 24px 0 0;
  background-color: #fef6e5;
  background: linear-gradient(
        264.4deg,
        #f4b621 11.83%,
        rgba(244, 182, 33, 0) 44.29%
      )
      padding-box,
    linear-gradient(264.4deg, #f4b621 11.83%, rgba(244, 182, 33, 0) 44.29%)
      border-box;
  height: 100%;
  transition: 0.4s ease-in-out;
}

.border_blue {
  border: 1px solid #443184 !important;
}

.border_orange {
  border: 2px solid #f4b621 !important;
  border-top: 0 !important;
  background-color: #fff5dd;
}

.recoded_faq .accordion-item {
  background-color: #fff5dd !important;
}

.recoded_faq .accordion-button {
  background-color: #fff5dd !important;
}

.about_faq .accordion-item {
  background-color: #f4f1ff !important;
  max-width: 790px !important;
  border-radius: 20px !important;
}

.about_faq .accordion-button {
  background-color: #f4f1ff !important;
  border-radius: 20px !important;
}

.custom_font {
  font-size: 80px !important;
  color: #f4dbad;
}

.bg_light {
  background-color: #513f95;
}

.custom_hight {
  min-height: 400px !important;
}

.next_icon {
  right: 15px !important;
  top: -30px;
  transform: translateY(-50%);
}

.prve_icon {
  left: 15px !important;
  top: -30px;
  transform: translateY(-50%);
}

.SuccessStories_image {
  margin-top: -220px !important;
}

.custom_slider_hight {
  height: 293px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.courses .carousel img {
  width: 50px !important;
}

.courses .carousel .slide img {
  width: 50px !important;
}

.table_contentli li {
  list-style-type: disc;
  list-style-type: number;
}

.table_content table {
  border-collapse: collapse;
  caption-side: bottom;
  width: 100% !important;
}

.table_content table td {
  padding: 10px;
  border: 1px solid #9e9999;
}

.table_content ul {
  padding-left: 0px !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #443184;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #f6990e;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f4b621;
}

/* -------------------------- */
.pl {
  display: block;
  margin: auto;
  width: 16em;
  height: auto;
}

.pl line {
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.pl__line1,
.pl__line9 {
  animation-name: line1;
}

.pl__line2,
.pl__line8 {
  animation-name: line2;
}

.pl__line3,
.pl__line7 {
  animation-name: line3;
}

.pl__line4,
.pl__line6 {
  animation-name: line4;
}

.pl__line5 {
  animation-name: line5;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 10%);
    --fg: hsl(var(--hue), 90%, 90%);
  }
}

/* Animations */
@keyframes line1 {
  from,
  8% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  18% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  28% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  38% {
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  48% {
    opacity: 1;
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  53% {
    opacity: 0;
    stroke-dashoffset: 31.99;
    transform: translate(8px, 16px);
  }

  56% {
    animation-timing-function: steps(1, start);
    opacity: 0;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  60% {
    animation-timing-function: ease-out;
    opacity: 1;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  70% {
    animation-timing-function: ease-in-out;
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  80% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  90% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  to {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }
}

@keyframes line2 {
  from,
  6% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  16% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  26% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  36% {
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  46% {
    opacity: 1;
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  51% {
    opacity: 0;
    stroke-dashoffset: 31.99;
    transform: translate(8px, 16px);
  }

  54% {
    animation-timing-function: steps(1, start);
    opacity: 0;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  58% {
    animation-timing-function: ease-out;
    opacity: 1;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  68% {
    animation-timing-function: ease-in-out;
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  78% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  88% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  98%,
  to {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }
}

@keyframes line3 {
  from,
  4% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  14% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  24% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  34% {
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  44% {
    opacity: 1;
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  49% {
    opacity: 0;
    stroke-dashoffset: 31.99;
    transform: translate(8px, 16px);
  }

  52% {
    animation-timing-function: steps(1, start);
    opacity: 0;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  56% {
    animation-timing-function: ease-out;
    opacity: 1;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  66% {
    animation-timing-function: ease-in-out;
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  76% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  86% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  96%,
  to {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }
}

@keyframes line4 {
  from,
  2% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  12% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  22% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  32% {
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  42% {
    opacity: 1;
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  47% {
    opacity: 0;
    stroke-dashoffset: 31.99;
    transform: translate(8px, 16px);
  }

  50% {
    animation-timing-function: steps(1, start);
    opacity: 0;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  54% {
    animation-timing-function: ease-out;
    opacity: 1;
    stroke-dashoffset: 32;
    transform: translate(0, 16px);
  }

  64% {
    animation-timing-function: ease-in-out;
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  74% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  84% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  94%,
  to {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }
}

@keyframes line5 {
  from {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  10% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  20% {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  30% {
    stroke-dashoffset: 0;
    transform: translate(0, 0);
  }

  40% {
    stroke-dashoffset: -16;
    transform: translate(0, 15px);
  }

  50% {
    stroke-dashoffset: -31;
    transform: translate(0, -48px);
  }

  58% {
    stroke-dashoffset: -31;
    transform: translate(0, 8px);
  }

  65% {
    stroke-dashoffset: -31.99;
    transform: translate(0, -24px);
  }

  71.99% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -31.99;
    transform: translate(0, -16px);
  }

  72% {
    animation-timing-function: ease-in-out;
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }

  82% {
    stroke-dashoffset: 16;
    transform: translate(0, 8px);
  }

  92%,
  to {
    stroke-dashoffset: 31.99;
    transform: translate(0, 16px);
  }
}

.overlay_images {
  width: 100%;
  height: 100px;
  background-color: #44318d;
  bottom: -500px;
  left: 0;
  border-radius: 0 0 20px 20px;
  opacity: 0;
  cursor: pointer;
  transition: all 400ms ease-in-out;
  border: 4px solid #fff;
}

.image_position:hover .overlay_images {
  opacity: 1;
  bottom: 0;
}

/* ----------------------------------------Radio--------------- */
.radio_container {
  display: block;
  position: relative;
  padding-left: 35px;

  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

select {
  outline: none;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #d7cfcf;
  border-radius: 50%;
}

.radio_container:hover input ~ .checkmark {
  background-color: #ccc;
}

.radio_container input:checked ~ .checkmark {
  background-color: #f6990e;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio_container input:checked ~ .checkmark:after {
  display: block;
}

.radio_container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.modal-content {
  background-color: transparent !important;
  border: 0 !important;
}

.borderstart_r {
  border-radius: 8px 0 0 0;
}

.borderend_r {
  border-radius: 0 8px 0 0;
}

/* --------------media classes-------------- */
@media (min-width: 1400px) {
  .custom_max_width_timeline_box2 {
    max-width: 500px !important;
    position: absolute;
    top: 200px !important;
    right: 200px;
  }

  .custom_max_width_timeline_box3 {
    max-width: 500px !important;
    position: absolute;
    top: 600px !important;
    left: 150px;
  }

  .custom_max_width_timeline_box4 {
    max-width: 400px !important;
    position: absolute;
    top: 800px !important;
    right: 0px;
  }

  .custom_max_width_timeline_box5 {
    max-width: 500px !important;
    position: absolute;
    top: 1090px !important;
    left: 200px;
  }

  .custom_max_width_timeline_box6 {
    max-width: 500px !important;
    position: absolute;
    top: 1470px !important;
    right: 200px;
  }

  .custom_max_width_timeline_box7 {
    max-width: 500px !important;
    position: absolute;
    top: 1590px !important;
    left: 0px;
  }

  .custom_max_width_timeline_box8 {
    max-width: 500px !important;
    position: absolute;
    top: 2030px !important;
    right: 180px;
  }

  .custom_max_width_timeline_box9 {
    max-width: 500px !important;
    position: absolute;
    top: 2380px !important;
    right: 0px;
  }
  .centerPlacementheight {
    height: 538px;
  }
}

@media (min-width: 992px) {
  .custom_lg_col {
    width: 20% !important;
  }

  .custom_bottom_margin {
    margin-top: -100px;
  }

  .next_icon {
    right: -45px !important;
    top: 44%;
    transform: translateY(-50%);
  }

  .prve_icon {
    left: -45px !important;
    top: 44%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1399px) {
  .custom_max_width_timeline_box2 {
    max-width: 500px !important;
    position: absolute;
    top: 200px !important;
    right: 200px;
  }

  .custom_max_width_timeline_box3 {
    max-width: 500px !important;
    position: absolute;
    top: 510px !important;
    left: 59px;
  }

  .custom_max_width_timeline_box4 {
    max-width: 400px !important;
    position: absolute;
    top: 700px !important;
    right: 0px;
  }

  .custom_max_width_timeline_box5 {
    max-width: 500px !important;
    position: absolute;
    top: 923px !important;
    left: 200px;
  }

  .custom_max_width_timeline_box6 {
    max-width: 500px !important;
    position: absolute;
    top: 1250px !important;
    right: 200px;
  }

  .custom_max_width_timeline_box7 {
    max-width: 372px !important;
    position: absolute;
    top: 1390px !important;
    left: 0px;
  }

  .custom_max_width_timeline_box8 {
    max-width: 500px !important;
    position: absolute;
    top: 1730px !important;
    right: 150px;
  }
  .centerPlacementheight {
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .navmanu {
    display: block;
  }

  .d-lg-none {
    display: none;
  }

  .sidebar {
    width: 100%;
    transition: all 400ms ease-in-out;
  }

  .centerPlacementheight {
    height: 100%;
  }

  .mt-134 {
    margin: 0;
  }

  :root {
    /*  ------------------------------- font size ------------------------------- */
    --fs-xs: 7px;
    --fs-sm: 12px;
    --fs-md: 15px;
    --fs-lg: 13px;
    --fs-xl: 17px;
    --fs-xxl: 18px;
    --fs-3xl: 14px;
    --fs-4xl: 18px;
    --fs-5xl: 21px !important;
    --fs-6xl: 22px;
    --fs-7xl: 23px;
    --fs-8xl: 37px;
    --fs-9xl: 29px;
    --fs-10xl: 36px;
    --fs-11xl: 42px;
    --fs-12xl: 80px;
  }

  .box {
    box-shadow: 0px 23px 81.19999694824219px 0px #00000040;
    background-color: #faf8ff;
    margin-top: -680px;
  }

  .custom_y_space {
    padding: 250px 0 !important;
  }
}

@media (max-width: 576px) {
  :root {
    /*  ------------------------------- font size ------------------------------- */
    --fs-xs: 7px;
    --fs-sm: 12px;
    --fs-md: 15px;
    --fs-lg: 16px;
    --fs-xl: 17px;
    --fs-xxl: 18px;
    --fs-3xl: 14px;
    --fs-4xl: 18px;
    --fs-5xl: 21px !important;
    --fs-6xl: 22px;
    --fs-7xl: 23px;
    --fs-8xl: 30px;
    --fs-9xl: 29px;
    --fs-10xl: 29px;
    --fs-11xl: 32px;
    --fs-12xl: 80px;
  }

  .fs_20 {
    font-size: 18px !important;
  }

  .custom_slider_image {
    max-width: 250px;
  }

  .selected-course {
    padding: 10px 0px !important;
    width: 100% !important;
  }

  .custom_width {
    width: 120px;
  }

  .course_title {
    line-height: 29.4px;
  }

  .centerPlacementheight {
    height: 100%;
  }

  .mt-134 {
    margin: 0;
  }
}

.option:hover {
  background-color: #f6990e !important;
  color: white !important;
}

.option {
  transition: all 300ms ease-in-out;
}
